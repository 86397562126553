.App {
  text-align: center;
}

body {
  color: white;
}
.professionalNewHands {
  /* height: 300px;
   */
   width:100%
}
.bestHandsImg {
  /* height: 300px; */
  width:100%
}

.portrait{
  width:100%
}

.fightMilk {
width: 10%;
height: 10%
}

.row {
  display: flex;
}

.column {
  flex: 50%;
  padding: 5px;
}

.App {
  background-color: rgb(116, 79, 40);
}

.App-header {
  background-color: rgb(215, 135, 50);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

footer{
  background-color: rgb(38, 38, 91);
}